var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.$t("admin.extra_shop.categories.title"))+" "),_c('CButton',{staticClass:"float-right",attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{ name: 'admin.extra_shop.categories.create' }}},[_vm._v(" "+_vm._s(_vm.$t("buttons.create"))+" ")])],1),_c('CCardBody',[_c('DataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.items,"fields":_vm.fields,"noItemsView":{
        noResults: _vm.$t('admin.no_results'),
        noItems: _vm.$t('admin.no_items')
      },"border":"","striped":"","hover":""},on:{"update:items":_vm.updateOrder},scopedSlots:_vm._u([{key:"show_order_handle",fn:function(){return [_c('td',{staticClass:"py-2 text-center"},[_c('CIcon',{staticClass:"handle",staticStyle:{"transform":"rotate(90deg)","cursor":"pointer"},attrs:{"name":"cilTransfer"}})],1)]},proxy:true},{key:"show_dishes",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'admin.extra_shop.categories.dishes',
              params: { category: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}},{key:"show_actions",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"primary","variant":item.is_hidden ? 'outline' : '',"square":"","size":"sm"},on:{"click":function($event){return _vm.toggleCategoryVisibility(item.id)}}},[_c('CIcon',{attrs:{"name":"cilOpacity"}})],1),_c('CButton',{staticClass:"mr-1",attrs:{"color":"primary","square":"","size":"sm","to":{
              name: 'admin.extra_shop.categories.edit',
              params: { category: item.id }
            }}},[_c('CIcon',{attrs:{"name":"cilPencil"}})],1),_c('CButton',{staticClass:"mr-1",attrs:{"color":"danger","square":"","size":"sm"},on:{"click":function($event){return _vm.deleteCategory(item.id)}}},[_c('CIcon',{attrs:{"name":"cilTrash"}})],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }