<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.extra_shop.categories.title") }}

      <CButton
        color="primary"
        variant="outline"
        square
        size="sm"
        class="float-right"
        :to="{ name: 'admin.extra_shop.categories.create' }"
      >
        {{ $t("buttons.create") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <DataTable
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        @update:items="updateOrder"
        border
        striped
        hover
      >
        <template #show_order_handle>
          <td class="py-2 text-center">
            <CIcon
              name="cilTransfer"
              class="handle"
              style="transform: rotate(90deg); cursor: pointer"
            ></CIcon>
          </td>
        </template>

        <template #show_dishes="{item}">
          <td class="py-2 text-center">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              :to="{
                name: 'admin.extra_shop.categories.dishes',
                params: { category: item.id }
              }"
            >
              {{ $t("admin.restaurants.go") }}
            </CButton>
          </td>
        </template>

        <template #show_actions="{item}">
          <td class="py-2 text-center">
            <CButton
              color="primary"
              :variant="item.is_hidden ? 'outline' : ''"
              square
              size="sm"
              class="mr-1"
              @click="toggleCategoryVisibility(item.id)"
            >
              <CIcon name="cilOpacity" />
            </CButton>

            <CButton
              color="primary"
              square
              size="sm"
              class="mr-1"
              :to="{
                name: 'admin.extra_shop.categories.edit',
                params: { category: item.id }
              }"
            >
              <CIcon name="cilPencil" />
            </CButton>

            <CButton
              color="danger"
              square
              size="sm"
              class="mr-1"
              @click="deleteCategory(item.id)"
            >
              <CIcon name="cilTrash"></CIcon>
            </CButton>
          </td>
        </template>
      </DataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  },

  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "name", label: this.$t("admin.name") },
        {
          key: "show_order_handle",
          label: this.$t("admin.order"),
          _style: "width: 130px"
        },
        {
          key: "show_dishes",
          label: this.$t("admin.extra_shop.categories.dishes.title"),
          _style: "width: 90px"
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 150px"
        }
      ]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      return this.$http
        .get("/api/admin/extra-shop/categories")
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    },
    updateOrder(items) {
      this.isLoading = true;
      this.$http
        .put(`/api/admin/extra-shop/categories/update-order`, {
          ids: items.map(item => item.id)
        })
        .then(() => this.getData());
    },
    toggleCategoryVisibility(category) {
      this.isLoading = true;
      this.$http
        .post(`/api/admin/extra-shop/categories/${category}/toggle-visibility`)
        .then(() => this.getData());
    },
    deleteCategory(id) {
      this.isLoading = true;
      this.$http
        .delete(`/api/admin/extra-shop/categories/${id}`)
        .then(() => this.getData());
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.extra_shop.title")
    };
  }
};
</script>
