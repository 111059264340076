<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.extra_shop.categories.dishes.title") }}

      <CButton
        color="primary"
        variant="outline"
        square
        size="sm"
        class="float-right"
        :to="{
          name: 'admin.extra_shop.categories.dishes.create',
          params: {
            category: $route.params.category
          }
        }"
      >
        {{ $t("buttons.create") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <DataTable
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        @update:items="updateOrder"
        border
        striped
        hover
      >
        <template #dish="{item}">
          <td class="py-2">
            {{ item.dish.name }}
          </td>
        </template>

        <template #show_order_handle>
          <td class="py-2 text-center">
            <CIcon
              name="cilTransfer"
              class="handle"
              style="transform: rotate(90deg); cursor: pointer"
            ></CIcon>
          </td>
        </template>

        <template #show_actions="{item}">
          <td class="py-2 text-center">
            <CButton
              color="primary"
              :variant="item.is_hidden ? 'outline' : ''"
              square
              size="sm"
              class="mr-1"
              @click="toggleVisibility(item.id)"
            >
              <CIcon name="cilOpacity" />
            </CButton>

            <CButton
              color="danger"
              square
              size="sm"
              class="mr-1"
              @click="deleteDish(item.id)"
            >
              <CIcon name="cilTrash"></CIcon>
            </CButton>
          </td>
        </template>
      </DataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  },

  data() {
    return {
      isLoading: true,
      items: []
    };
  },

  created() {
    this.getData();
  },

  computed: {
    fields() {
      return [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "name", label: this.$t("admin.name") },
        {
          key: "price_sell",
          label: this.$t("admin.price_sell"),
          _style: "width: 200px"
        },
        {
          key: "show_order_handle",
          label: this.$t("admin.order"),
          _style: "width: 90px"
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 150px"
        }
      ];
    }
  },

  methods: {
    getData() {
      return this.$http
        .get(
          `/api/admin/extra-shop/categories/${this.$route.params.category}/dishes`
        )
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    },
    toggleVisibility(dish) {
      this.isLoading = true;
      this.$http
        .post(`/api/admin/extra-shop/dishes/${dish}/toggle-visibility`)
        .then(() => this.getData());
    },
    updateOrder(items) {
      this.isLoading = true;
      this.$http
        .put(
          `/api/admin/extra-shop/categories/${this.$route.params.category}/dishes/update-order`,
          {
            ids: items.map(item => item.id)
          }
        )
        .then(() => this.getData());
    },
    deleteDish(id) {
      this.isLoading = true;
      this.$http
        .delete(`/api/admin/extra-shop/dishes/${id}`)
        .then(() => this.getData());
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.restaurants.sections.dishes.title")
    };
  }
};
</script>
