<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("admin.extra_shop.categories.dishes.create.title") }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="store"
      >
        {{ $t("buttons.save") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
        {{ errors.message }}
        <ul>
          <li v-for="(error, field) in errors.errors" :key="field">
            {{ error[0] }}
          </li>
        </ul>
      </CAlert>
      <CRow>
        <CCol sm="6">
          <multiselect
            v-model="restaurant"
            track-by="id"
            label="name"
            placeholder="Choose a restaurant"
            :options="restaurants"
          />
        </CCol>
        <CCol sm="6">
          <multiselect
            v-model="selectedDishes"
            :options="dishes"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select dishes"
            label="name"
            track-by="id"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect
  },

  data() {
    return {
      isLoading: false,
      errors: [],
      restaurants: [],
      restaurant: null,
      dishes: [],
      selectedDishes: []
    };
  },

  watch: {
    restaurant() {
      this.getDishes();
    }
  },

  created() {
    this.getRestaurants();
  },

  methods: {
    getRestaurants() {
      this.isLoading = true;

      this.$http.get(`/api/admin/restaurants`).then(response => {
        this.restaurants = response.data.data;

        this.isLoading = false;
      });
    },
    getDishes() {
      this.errors = [];
      this.isLoading = true;

      this.$http
        .get(`/api/admin/restaurants/${this.restaurant.id}/dishes`)
        .then(response => {
          this.dishes = response.data.data;

          this.isLoading = false;
        });
    },
    store() {
      this.isLoading = true;
      this.errors = [];

      this.$http
        .post(
          `/api/admin/extra-shop/categories/${this.$route.params.category}/dishes`,
          {
            ids: this.selectedDishes.map(dish => dish.id)
          }
        )
        .then(() =>
          this.$router.push({
            name: "admin.extra_shop.categories.dishes",
            params: { category: this.$route.params.category }
          })
        )
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.extra_shop.categories.dishes.create.title")
    };
  }
};
</script>
